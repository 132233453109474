.points {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .userId {
        padding-top: 4rem;
        padding-bottom: 4rem;
        text-align: center;
    }

    .points-sum {
        font-size: 2.5rem;
        margin-left: auto;
        margin-right: auto;
        font-weight: 600;
    }

    .copy-ref-link {
        display: flex;
        flex-direction: row;
        column-gap: .5rem;
        margin: 2rem auto;
        text-decoration: underline;
        align-items: center;
        font-style: italic;
    }

    .pints-actions {
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;

        button {
            font-size: 1rem;
            padding: .5rem;
            background-color: white;
            border: 1px solid lightgray;
            border-radius: 10px;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .transactions {
        margin-top: 2rem;
        width: 100%;
        height: auto;
        border-top: 1px solid lightgray;
        display: flex;
        flex-direction: column;
        
        .transactions-title {
            font-size: 1rem;
            font-weight: 500;
            text-align: center;
            padding: .5rem 1rem;
            width: 100%;
            border-bottom: 1px solid lightgray;
        }

        .histories {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            padding: 0 .3rem;
            padding-top: 1rem;

            .empty-transactions {
                text-align: center;
                justify-self: center;
                height: 100%;
                margin-top: 2rem;
            }

            .history {
                display: grid;
                grid-template-columns: 2fr 8fr 2fr;
                height: 2.5rem;

                .icon {
                    height: 2.3rem;
                    width: 2.3rem;
                    // background-color: red;
                    border: 1px solid lightgray;
                    border-radius: 20px;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 60%;
                        height: auto;
                    }
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-between;

                    .reason {
                        font-weight: 600;
                    }

                    .date {
                        opacity: .5;
                        font-size: .8rem;
                    }
                }

                .sum {
                    margin: auto;
                    font-size: 1.1rem;
                }
            }
        }
    }
}

.transfer-modal {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .transfer-header {
        width: 100%;

        h2 {
            padding: 0;
            margin: 0;
            text-align: center;
        }   
    }

    .transfer-body {
        display: flex;
        flex-direction: column;
        row-gap: .5rem;
        margin-bottom: 1.5rem;
    }

    .transfer-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        button {
            margin-left: auto;
            margin-right: auto;
            font-size: 1rem;
            padding: .5rem;
            background-color: whitesmoke;
            border: 1px solid lightgray;
            border-radius: 10px;
            font-weight: 600;
            width: 40%;
        }
    }
}