@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.cart {
    width: 100%;
    height: 100%;
    padding: 3rem 0 3rem 0;
    font-family: "Montserrat", sans-serif;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    div {
        .product {
            display: grid;
            grid-template-columns: 1fr 2fr;
            column-gap: 20px;
            padding: 5%;
            border-bottom: 1px solid grey;
            height: 13rem;
            width: 100%;

            .description {
                display: grid;
                grid-template-rows: 1fr 1fr 1fr;
                // flex-direction: column;
                row-gap: .5rem;
                // height: auto;
                overflow: hidden;


                label:nth-child(3) {
                    width: 100%;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 5;
                    /* start showing ellipsis when 3rd line is reached */
                    white-space: pre-wrap;
                    /* let the text wrap preserving spaces */
                }
            }
        }

        .buttons {
            border-bottom: 1px solid grey;
            width: 100%;
            padding-top: 2%;
            padding-bottom: 2%;
            display: flex;
            justify-content: space-around;

            span {
                display: flex;
                flex-direction: row;
                column-gap: 1rem;
                align-items: center;
            }
        }
    }

    .last-btn {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        background-color: white;
        padding: 5%;
        display: flex;
        width: 100%;
        height: auto;
        position: fixed;
        bottom: 3rem;
        left: 0;
        right: 0;
        border: none;

        width: 100%;

        span {
            width: 100%;
            padding: 5%;
            background-color: whitesmoke;
            color: black;
            border-radius: 10px;
            text-align: center;
            font-weight: 700;
        }
    }
}

input {
    margin-top: .5rem;
    margin-bottom: .5rem;

    border: 1px solid rgba(0, 0, 0, 0.404);
    border-radius: 7px;
    text-align: left;
    padding: 3%;
    color: rgb(102, 102, 102);
    outline: none;
    font-size: 1rem;
    width: 100%;
}

input:focus {
    color: black;
}

.btn-color {
    background: #dfdfdf;
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 35px;
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem;
}

.save-changes-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    input {
        width: 1rem;
        height: 1rem;
        margin: 0;
        cursor: pointer;
    }
}