.payment-success {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-rows: 5fr 3fr 1fr 1fr;
    padding: 1.5rem;

    img {
        width: 50%;
        height: auto;
        margin: auto;
    }

    label {
        margin: auto;
        text-align: center;
        line-height: 2rem;
        font-family: 'Raleway';
    }

    button {
        background-color: whitesmoke;
        border: 1px solid lightgray;
        border-radius: 1rem;
        height: 60%;
        width: 80%;
        margin: auto;
        font-weight: 600;
        font-family: 'Raleway';
        font-size: 1rem;
    }
}