.header {
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 4fr;
  padding: 0.5rem 0;
  font-family: "Raleway", sans-serif;
  position: fixed;
  top: 0;
  background-color: white;
  border-bottom: 1px solid whitesmoke;
  z-index: 100;

  label {
    margin-left: 0.5rem;
    font-weight: 500;
    font-size: 1.8rem;
    font-family: "Alumni Sans", sans-serif !important;
  }

  label:nth-child(2) {
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-right: 1rem;
    align-items: center;
    width: 100%;

    label {
      font-size: 1rem;
      font-family: "Raleway", sans-serif !important;
    }

    span {
      font-size: 0.5rem;
      // height: 15px;
    }

    img {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
}

.btn-color {
  background: #dfdfdf;
  border: none;
  border-radius: 10px;
  width: 130px;
  height: 35px;
}
