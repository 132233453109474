.news {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 5rem 0 4rem 0;
    font-family: 'Roboto';
    row-gap: 1.5rem;

    .news-block {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid lightgray;
        padding: 1rem 0;
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        label:first-child {
            padding: 0 0 1rem 1rem;
            font-weight: 600;
            font-size: 1.2rem;
        }

        .news-content {
            padding: 0 1rem;
            width: 100%;
        }

        label:last-child {
            margin-left: auto;
            padding: 1rem 1rem 0 0;
            font-size: .8rem;
            opacity: .5;

            span {}
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}