.catalog {
    width: 100%;
    height: auto;
    padding: 5rem 0rem 4rem 0rem; // top right bottom left

    .product {
        width: 100%;
        height: auto;
        // border: 1px solid lightgray;
        // border-radius: 5px;
        margin-left: auto;
        margin-right: auto;

        display: flex;
        flex-direction: column;
        row-gap: .5rem;

        .product-header {
            // padding:0 15px;
            text-align: start;
            display: flex;
            flex-direction: column;

            label {
                font-weight: 600;
                font-size: 1.1rem;
                padding-left: 1rem;
            }
        }

        .product-body {

            display: flex;
            flex-direction: column;
            row-gap: .5rem;

            img {
                width: 100%;
                height: auto;
            }

            label {
                padding: 0 1rem;
                font-size: 1.1rem;
            }

            // label {
            //     padding: 0 15px;
            //     font-size: .8rem;
            //     font-family: 'Montserrat';
            //     font-weight: 500;
            // }

            video {
                border-radius: 5px;
            }
        }

        .product-footer {
            display: flex;
            justify-content: space-between;
            padding: .5rem 1rem;

            .price {
                font-size: 1.2rem;
            }

            .add-to-cart {
                display: flex;
                flex-direction: row;
                justify-content: center;
                column-gap: 5px;
            }
        }
    }
}