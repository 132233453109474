.orders {
    width: 100%;
    height: auto;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 3rem 0 4rem 0;
    font-family: 'Raleway', sans-serif !important;

    h2 {
        margin-left: 1rem;
        margin-bottom: 1rem;
    }

    .order {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        width: 90%;
        margin: auto;
        border: 1px solid lightgray;
        padding: 1rem 1rem;
        border-radius: 10px;

        button {
            background-color: whitesmoke;
            border: 1px solid lightgray;
            padding: .5rem;
            font-weight: 700;
            border-radius: 10px;
            font-family: 'Raleway', sans-serif !important;
            outline: none;
        }
    }
}