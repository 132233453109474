.profile {
    padding-top: 3.2rem;
    width: 100%;
    height: 100%;

    .profile-image {
        height: 5rem;
        width: 5rem;
        background-color: white;
        border: 1px solid whitesmoke;
        border-radius: 50px;
        position: absolute;
        top: 28%;
        /* position the top  edge of the element at the middle of the parent */
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 40px;
            height: 40px;
        }
    }

    .profile-header {
        margin-top: 10rem;
        height: auto;
        background-color: whitesmoke;
        box-shadow: 0px -5px 100px 40px lightgray;
        text-align: center;
        padding-top: 4rem;
        display: flex;
        flex-direction: column;

        label {
            font-size: 1.2rem;
        }

        span {
            color: lightgray;

            svg {
                margin-top: auto;
                margin-bottom: auto;
                height: 10px;
                width: 10px;

                g {
                    stroke: lightgray;
                }
            }
        }
    }

    .profile-content {
        background-color: whitesmoke;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        padding-top: 1rem;

        .balance {
            padding-top: 2rem;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            .balance-section {
                display: flex;
                flex-direction: column;
                text-align: center;
                border: 3px solid white;
                background-color: white;
                border-radius: 5px;
                padding: .5rem 0;
                width: 8rem;
                row-gap: .5rem;
                font-size: .9rem;

                span {
                    display: flex;
                    flex-direction: row;
                    column-gap: 1rem;
                    justify-content: space-evenly;
                    align-items: center;
                }
            }
        }

        .sections {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            padding-bottom: 4rem;
            background-color: whitesmoke;

            .category-header {
                margin-top: 1rem;
                margin-left: .5rem;
                margin-bottom: .5rem;
                font-weight: 600;
                color: lightgray;
                text-transform: uppercase;
                font-size: .8rem;
            }

            .category-header:first-child {
                margin-top: 0;
            }

            .profile-section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                background-color: white;
                padding: 10px;
                border-bottom: 1px solid whitesmoke;

                label {
                    font-size: 1rem;
                    margin-top: auto;
                    margin-bottom: auto;
                }

                svg {
                    width: 15px;
                    height: 15px;

                    g {

                        stroke: lightgray;
                    }
                }
            }
        }
    }
}
.outlined {
    text-decoration: underline;
}
.iframe_border {
}