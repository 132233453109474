.footer {
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0%;
    left: 0;
    right: 0;
    background-color: whitesmoke;
    border-top: 1px solid lightgray;

    button {
        svg {
            height: 24px;
            width: 24px;
        }
    }
}