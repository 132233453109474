.notifications {
    width: 100%;
    height: auto;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-bottom: 4rem;
    padding-top: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;


    .notification-body {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        .notification {
            width: 100%;
            height: auto;
            display: grid;
            grid-template-columns: 1fr 5fr;
            column-gap: .5rem;

            .logo {
                height: 3.5rem;
                width: 3.5rem;
                background-color: lightgray;
                border-radius: 50px;
                margin: auto;
            }



            label {
                font-size: .8rem;

                span {
                    color: lightgray;
                }
            }
        }
    }
}