.signup {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Roboto', 'sans-serif';
    padding: 5%;    
    position: absolute;
    z-index: 101;
    background-color: white;
    justify-content: center;
    span {
        display: none;
        border: none;
        background-color: transparent;
    }

    label {
        margin-left: auto;
        margin-right: auto;
        font-size: 2.7rem;
        font-family: 'Montserrat';
        font-weight: 600;
    }
    .com-logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    .company-logo {
        transform: scale(100%);
        height: 10rem;
        width: 10rem;
        border-radius: 3rem;
        // background-color: lightgray;
        background-position: top -10% right 50%;
        background-size: 100%;
        background-repeat: no-repeat;
        border: none;
    }
}
    .signup-form {
        width: 100%;
        text-align: center;
        display: grid;
        grid-template-rows: .2fr .4fr .4fr .1fr .6fr;
        row-gap: .7rem;
        margin-top: 1rem;
       
        .underline {
            font-size: .7rem;
            white-space: nowrap;
            text-decoration: underline;
        }
        span {
            display: flex;
        }

        label {
            font-size: 1.5rem;
            font-weight: 600;
        }

        h4 {
            font-weight: 400;
        }

        input {
            border: 1px solid rgba(0, 0, 0, 0.404);
            border-radius: 7px;
            text-align: left;
            padding: 5%;
            color: rgb(102, 102, 102);
            outline: none;
            font-size: 1rem;
        }

        input:focus {
            color: black;
        }

        button {
            background-color: rgb(0, 183, 255);
            border: none;
            border-radius: 7px;
            width: 100%;
            padding: 4%;
            transition: all 0.3s ease-in-out;
            color: white;
            font-size: 1rem;
            font-weight: 600;
        }

        button:hover {
            background-color: rgb(0, 154, 214);
        }
    }
}

.logo-label {
    font-size: 4rem !important;
    font-weight: 600 !important;
    font-family: 'Alumni Sans', sans-serif !important;
}

.btn-close {
    width: 100%;
    margin-top: 1rem;
    padding: .5rem 0;
    border-radius: 5px;
    border: 1px solid lightgray;
    font-weight: 600;
    font-family: 'Montserrat';
}

