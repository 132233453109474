.signin {
    width: 100%;
    height: 100vh;
    margin: 0;
    display: grid;
    align-items: center;
    grid-template-rows: 7fr 1fr;
    font-family: 'Roboto', 'sans-serif';
    row-gap: 5rem;
    position: absolute;
    top: 0%;
    bottom: 0;
    background-color: white;
    z-index: 999;

    span {
        border: none;
        background-color: transparent;
    }
    
    .signin-form {
        padding: 5%;
        width: 100%;
        text-align: center;
        display: grid;
        align-items: center;
        grid-template-rows: 2.5fr .20fr .20fr .1fr .20fr;
        row-gap: .7rem;
        .com-logo {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        .company-logo {
            transform: scale(100%);
            height: 10rem;
            width: 10rem;
            border-radius: 3rem;
            // background-color: lightgray;
            background-position: top -10% right 50%;
            background-size: 100%;
            background-repeat: no-repeat;
            border: none;
        }
    }
        label {
            font-size: 4rem;
            font-weight: 600;
            font-family: 'Alumni Sans', sans-serif;
        }

        input {
            border: 1px solid rgba(0, 0, 0, 0.404);
            border-radius: 7px;
            text-align: left;
            padding: 5%;
            color: rgb(102, 102, 102);
            outline: none;
            font-size: 1rem;
        }

        input:focus {
            color: black;
        }

        span {
            text-align: end;
            color: rgb(0, 132, 255);
            font-weight: 600;
        }

        button {
            background-color: rgb(0, 183, 255);
            border: none;
            border-radius: 7px;
            width: 100%;
            padding: 4%;
            transition: all 0.3s ease-in-out;
            color: white;
            font-weight: 600;
            font-size: 1rem;
        }

        button:hover {
            background-color: rgb(0, 154, 214);
        }
    }

    .signin-footer {
        display: flex;
        justify-content: center;
        padding-top: 5%;
        column-gap: 2%;
        border-top: 1px solid rgb(179, 179, 179);

        button {
            font-weight: 600;
        }
    }
}