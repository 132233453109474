.home {
    width: 100%;
    height: auto;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-bottom: 3.5rem;
    font-family: 'Roboto', 'sans-serif';

    .header {
        width: 100%;
        display: grid;
        grid-template-columns: 5fr 1fr 1fr;
        padding: 1rem 0;
        position: fixed;
        background-color: white;
        z-index: 2;
        label {
            margin-left: .5rem;
            font-weight: 500;
            font-size: 1rem;
        }

        span {
            margin: auto;

            img {
                height: 1.2rem;
                width: 1.2rem;
            }
        }
    }

    .products-body {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        padding-top: 13%;
        padding:16% 0 0 0;
        .products-history {
            padding: 0 5%;
            display: flex;
            flex-direction: row;
            width: 100%;
            span {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-right: 3%;
                span {
                    width: 80px;
                    height: 80px;
                    background-color: whitesmoke;
                    font-size: 0;
                    border-radius: 50%;
                    margin-bottom: 5%;
                    z-index: 0;
                }
                svg {
                    position: absolute;
                    z-index: 1;
                    left: 70px;
                    top: 110px;
                }
                small {
                    font-size: 1.5vh;
                }
            }
        }
        .products-publish {
            display: grid;
            grid-template-areas: 
            "header"
            "publish"
            ;
            align-items: center;
            margin-top: 5%;
            
            .publish-header {
                display: grid;
                grid-area: header;
                padding: 0 5%;
                grid-template-areas: 
                "logo id id id id id id id id set"
                "logo place place place place place place place place set"
                ;
                align-items: center;
                span:nth-child(1){
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    grid-area: logo;
                    background-color: whitesmoke;
                    font-size: 0;
                }
                small:nth-child(2){
                    grid-area: id;
                    font-size: 1.4vh;
                }
                small:nth-child(3){
                    font-size: 1.2vh;
                    grid-area: place;
                }
                span:nth-child(4){
                    display: flex;
                    justify-content: end;
                    grid-area: set;
                    font-size: 0;
                }
            
            }
            .publish{
                grid-area: publish;
                background-color: whitesmoke;
                font-size: 0;
                height: 200px;
            }
            .publish-body {
                padding: 2% 5%;
                display: grid;
                grid-template-areas: 
                "love comment share share share share share share share share share share share puka"
                " love-sch love-sch love-sch love-sch love-sch love-sch love-sch love-sch love-sch love-sch love-sch love-sch love-sch love-sch"
                " publishing publishing publishing publishing publishing publishing publishing publishing publishing publishing publishing publishing publishing publishing"
                " commentators commentators commentators commentators commentators commentators commentators commentators commentators commentators commentators commentators commentators commentators"
                ;
                span:nth-child(1) {
                    grid-area: love;
                }
                span:nth-child(2) {
                    grid-area: comment;
                }
                span:nth-child(3) {
                    grid-area: share;
                }
                span:nth-child(4) {
                    display: flex;
                    justify-content: end;
                    grid-area: puka;
                }
                small:nth-child(5) {
                    font-weight: 600;
                    grid-area: love-sch;
                }
                small:nth-child(6) {
                    grid-area: publishing;
                    font-weight: 600;
                    label {
                        font-weight: 400;
                    }
                }
                label:nth-child(7) {
                    font-size: 1.8vh;
                    font-weight: 500;
                    color: gray;
                    grid-area: commentators;
                }
            }
        }
    }
    .navbar {
        width: 100%;
        height: auto;
        position: fixed;
        bottom: 0%;
        left: 0;
        right: 0;
        background-color: whitesmoke;
        border-top: 1px solid lightgray;
    }
}