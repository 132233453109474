.home {
    width: 100%;
    height: auto;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 5rem 0 4rem 0;
    font-family: 'Raleway', sans-serif !important;

    .body {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        .content-group {
            padding: 0 .5rem;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            .company-logo {
                height: 6rem;
                width: 6rem;
                border-radius: 3rem;
                // background-color: lightgray;
                background-position: top -10% right 50%;
                background-size: 150%;
                background-repeat: no-repeat;
                border: 1px solid whitesmoke;
                margin: auto;
                cursor: pointer;
            }

            .company-info {
                font-size: 1rem;
                margin: auto;
                cursor: pointer;
            }
        }

        .content {
            width: 100%;
            height: auto;
            display: flex;
            row-gap: .5rem;
            flex-direction: column;
            padding: 0 .5rem;
            line-height: 150%;

            .search-link {
                text-decoration: underline;
            }

            a {
                color: black;
            }

            label:nth-child(1) {
                font-weight: 500;
                font-size: 1rem;
            }

            label:nth-child(2) {
                font-size: .9rem;
            }
        }

        .reg-btn {
            display: flex;
            flex-direction: row;
            width: 100%;

            .registration-btn {
                width: 100%;
                height: auto;
                // text-align: center;
                padding: .5rem 0;
                margin: 0 .3rem;
                border: 2px solid whitesmoke;
                border-radius: 5px;
                font-size: 1rem;
                font-weight: 700;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                line-height: 150%;
            }

            // .registration-btn:last-child {
            //     display: none;
            // }
        }

        .actual {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            column-gap: 1rem;
            overflow-x: auto;
            text-align: center;
            padding: 0.7rem .5rem;

            .story {
                width: auto;
                height: auto;
                display: flex;
                flex-direction: column;
                row-gap: .5rem;
                align-items: center;

                div {
                    height: 3.5rem;
                    width: 3.5rem;
                    // background-color: lightgray;
                    border-radius: 2rem;
                    border: 1px solid whitesmoke;
                }

                label {
                    font-size: .7rem;
                }
            }
        }

        .categories-group {
            display: flex;
            flex-direction: column;
            width: 100%;

            .categories {
                width: 100%;
                height: auto;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr 1fr;
                gap: 1px 1px;
                grid-template-areas:
                    ". . ."
                    ". . ."
                    ". . .";

                .category {
                    height: 10rem;
                    width: auto;
                    background-color: lightgray;
                    display: flex;
                    justify-content: start;
                    align-items: flex-end;
                    text-align: center;

                    label {
                        font-size: .7rem;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 20%;
                        background: rgba(255, 255, 255, 0.5);
                        // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
                        // backdrop-filter: blur(20px);
                        // -webkit-backdrop-filter: blur(20px);
                    }
                }
            }
        }
    }
}