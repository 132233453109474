.search {
    height: auto;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .search-input {
        width: 100%;
        padding: 1rem .5rem;
        border-radius: 5px;
        border: 1px solid lightgray;
        transition: all .3s ease-in-out;
        font-size: 1rem;
    }

    .search-input:focus {
        // box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
        outline: none;
    }

    .disease {
        width: 100%;
        height: auto;
        // border: 1px solid lightgray;
        // border-radius: 5px;
        margin-left: auto;
        margin-right: auto;

        display: flex;
        flex-direction: column;
        row-gap: .5rem;

        .disease-header {
            // padding:0 15px;
            text-align: start;
            display: flex;
            flex-direction: column;

            label {
                font-weight: 600;
                font-size: 1.1rem;
                padding-left: 1rem;
            }
        }

        .disease-body {

            display: flex;
            flex-direction: column;
            row-gap: .5rem;

            img {
                width: 100%;
                height: auto;
            }

            label {
                padding: 0 1rem;
                font-size: 1.1rem;
            }

            // label {
            //     padding: 0 15px;
            //     font-size: .8rem;
            //     font-family: 'Montserrat';
            //     font-weight: 500;
            // }

            video {
                border-radius: 5px;
            }
        }

        .disease-footer {
            display: flex;
            justify-content: space-between;
            padding: .5rem 1rem;

            .price {
                font-size: 1.2rem;
            }

            .add-to-cart {
                display: flex;
                flex-direction: row;
                justify-content: center;
                column-gap: 5px;
            }
        }
    }
}